<template>
  <div class="block relative overlow-hidden scroll-smooth" @touchstart="handleTouchStart" ref="container">
    <h1 class="visuallyhidden">{{ pagetitle }}</h1>
    <p class="visuallyhidden">{{ pagecontent }}</p>
    <script type="application/ld+json">
      { "@context": "https://schema.org", "@type": "Webpage", "name": "{{ pagetitle }}", "description": "{{ pagecontent }}", "publisher": {"@type": "Organization", "name": "Lonely Planet Italia", "logo": {"@type": "ImageObject", "url": "https://lonelyplanet--media.s3.eu-west-1.amazonaws.com/public/lonely-planet-logo.jpg", "width": 750, "height": 160, "encodingFormat": "image/jpeg" }, "url": "https://www.lonelyplanetitalia.it" }
    </script>
    <!-- Badge -->
    <transition name="fromleft">
      <div v-if="pages.length"
        class="fixed z-10 top-80 md:top-128 left-0 bg-black-500 bg-opacity-80 p-y-10 p-l-8 lg:p-y-16 lg:p-l-16 p-r-32 rounded-r-full flex flex-row gap-x-10 items-center cursor-pointer"
        @click.prevent="asideShow = true">
        <div class="w-32 h-32 rounded-full bg-white flex items-center justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20" viewBox="0 0 24 24">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1.5"
              d="M4 7h3m13 0h-9m9 10h-3M4 17h9m-9-5h16" />
          </svg>
        </div>
        <div class="flex flex-col gap-y-4">
          <span class="font-tusker font-semibold text-white uppercase leading-none text-lg">
            {{ currentSection.name }}
          </span>
          <div class="relative leading-none h-4 w-full bg-black-400 rounded-8 overflow-hidden transform transition-all">
            <div class="absolute bg-white h-full transform transition-all"
              :style="'width:' + (activePage + 1) * 10 + '%'"></div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Aside -->
    <transition name="aside">
      <aside v-if="asideShow"
        class="fixed overflow-x-hidden left-0 top-0 h-screen bg-black-500 w-320 transform transition-all z-top">
        <slot name="aside" :scope="{ toggleCollapse, fnToggleCollapse, fnScroll, fnCloseAside }"></slot>
      </aside>
    </transition>
    <!-- Horizontal dots -->
    <transition name="frombottom">
      <div v-if="pages.length && activePage > 0"
        class="hidden lg:flex fixed w-full items-center justify-center bottom-32 z-9" key="dots">
        <nav class="m-x-auto inline-flex justify-center bg-black-500 rounded-24 bg-opacity-80 p-x-16 p-y-8">
          <!-- <button class="block cursor-pointer p-x-4 p-y-4 text-white" @click="fnPreviousPage()">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m15 6l-6 6l6 6" />
            </svg>
          </button> -->
          <button v-for="(page, index) in pages" :key="index" class="block cursor-pointer p-x-4 p-y-4"
            @click="fnScroll(index)">
            <template v-if="index === 0">
              <template v-if="activePage === index">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-white" viewBox="0 0 256 256">
                  <path fill="currentColor"
                    d="M224 115.55V208a16 16 0 0 1-16 16h-40a16 16 0 0 1-16-16v-40a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v40a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16v-92.45a16 16 0 0 1 5.17-11.78l80-75.48l.11-.11a16 16 0 0 1 21.53 0a1.14 1.14 0 0 0 .11.11l80 75.48a16 16 0 0 1 5.08 11.78Z" />
                </svg>
              </template>
              <template v-else>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 text-white" viewBox="0 0 256 256">
                  <path fill="currentColor"
                    d="m221.56 100.85l-79.95-75.47l-.16-.15a19.93 19.93 0 0 0-26.91 0l-.17.15l-79.93 75.47a20.07 20.07 0 0 0-6.44 14.7V208a20 20 0 0 0 20 20h48a20 20 0 0 0 20-20v-44h24v44a20 20 0 0 0 20 20h48a20 20 0 0 0 20-20v-92.45a20.07 20.07 0 0 0-6.44-14.7ZM204 204h-40v-44a20 20 0 0 0-20-20h-32a20 20 0 0 0-20 20v44H52v-86.72l76-71.75l76 71.75Z" />
                </svg>
              </template>
            </template>
            <template v-else>
              <div class="w-10 h-10 transform transition-all duration-500 rounded-16 border-1 border-white"
                :class="{ 'bg-white': activePage == index }">
              </div>
            </template>
          </button>
          <!-- <button class="block cursor-pointer p-x-4 p-y-4 text-white" @click="fnNextPage()">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20" viewBox="0 0 24 24">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m9 6l6 6l-6 6" />
            </svg>
          </button> -->
        </nav>
      </div>
    </transition>
    <!-- Content -->
    <slot name="content"
      :scope="{ toggleContent, fnNextPage, fnToggleContent, toggleCollapse, fnToggleCollapse, currentSection }">
    </slot>
    <!-- Close button -->
    <div @click="fnToggleContent"
      class="close-button block fixed top-16 right-16 z-top bg-white shadow-md h-48 w-48 rounded-full flex flex-row items-center justify-center cursor-pointer"
      :class="{ 'is-showing': closeButton }">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-40 h-40 text-black" viewBox="0 0 24 24">
        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
          d="m7 7l10 10M7 17L17 7" />
      </svg>
    </div>
    <!-- PREVIOUS Button -->
    <transition name="fromleft">
      <div v-if="hasPreviousPage" class="fixed top-0 bottom-0 left-16 w-auto flex items-center justify-center"
        @click="fnPreviousPage()">
        <div
          class="w-64 h-64 rounded-full bg-black bg-opacity-30 hover:bg-opacity-50 flex items-center justify-center transition-all cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-52 h-52 text-white m-r-4" viewBox="0 0 24 24">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="m15 5l-6 7l6 7" />
          </svg>
        </div>
      </div>
    </transition>
    <!-- NEXT Button -->
    <transition name="fromright">
      <div v-if="hasNextPage" class="fixed top-0 bottom-0 right-16 w-auto flex items-center justify-center"
        @click="fnNextPage()">
        <div
          class="w-64 h-64 rounded-full bg-black bg-opacity-30 hover:bg-opacity-50 flex items-center justify-center transition-all cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-52 h-52 text-white m-l-4" viewBox="0 0 24 24">
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="m9 5l6 7l-6 7" />
          </svg>
        </div>
      </div>
    </transition>
    <!-- Pre-Screen-->
    <div @click="fnResetPopovers" class="pre-screen block fixed inset-0 z-forward" :class="{ 'is-showing': preScreen }">
    </div>
  </div>
</template>

<script>

import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
elementScrollIntoViewPolyfill();

export default {
  name: 'BestInTravel',
  data() {
    return {
      activePage: 0,
      asideShow: false,
      browserHeight: 0,
      closeButton: false,
      inMove: false,
      inMoveDelay: 200,
      inMoveStay: 500,
      pages: [],
      pagesData: [],
      preScreen: false,
      toggleCollapse: null,
      toggleContent: false,
      toggleContentData: null,
      tolerance: 60,
      touchStartX: false,
      touchStartY: false,
      touchTolerance: 100,

    }
  },
  props: {
    baseroute: {
      required: false
    },
    forcepage: {
      required: false
    }
  },
  mounted() {
    this.onStartUp()
    this.setListeners()
  },
  beforeDestroy() {
    this.unsetListeners()
  },
  watch: {
    asideShow: function (e) {
      if (e) {
        this.preScreen = true
        this.unsetListeners()
      } else {
        this.preScreen = false
        this.setListeners()
      }
    },
    toggleContent: function (e, o) {
      if (e) {
        this.preScreen = true
        this.closeButton = true
        this.unsetListeners()
      } else {
        this.closeButton = false
        this.toggleContentData.scrollTop = 0
        this.toggleContentData = null
        this.preScreen = false
        this.setListeners()
      }
    }
  },
  computed: {
    currentSection: function () {
      return this.pagesData[0]
    },
    currentPageData: function () {
      return this.pagesData[this.activePage]
    },
    pagetitle: function () {
      if (this.pagesData.length) {
        return this.activePage > 0 ? this.currentPageData['name'] : 'Top 10 ' + this.currentPageData['name']
      }
      return false
    },
    pagecontent: function () {
      if (this.pagesData.length) {
        return this.currentPageData['content']
      }
      return false
    },
    pageJsonLD: function () {
      return 'page jsonld data'
    },
    hasPreviousPage: function () {
      return this.pages.length && this.activePage > 0
    },
    hasNextPage: function () {
      return this.pages.length && (this.activePage < this.pages.length - 1)
    }
  },
  methods: {
    fnToggleCollapse: function (index) {
      this.toggleCollapse = this.toggleCollapse === index ? null : index
    },
    fnCloseAside: function () {
      this.asideShow = false
    },
    fnResetPopovers: function () {
      this.asideShow = false
      this.toggleContent = false
      // Inserire qui il chiudi description del place
    },
    fnShowShareWindow: function () {
      console.log(this.currentPageData, this.currentCardData)
    },
    onStartUp: function (e) {
      this.fnCalculatePageHeight()
      this.fnScrollbarWidth()
      this.fnGetPages()
      this.fnGetFirstScroll()
    },
    onResize: function (e) {
      this.fnCalculatePageHeight()
      this.fnScrollbarWidth()
      this.fnScroll(this.activePage)
    },
    fnCalculatePageHeight: function () {
      this.browserHeight = window.innerHeight + 'px'
    },
    fnScrollbarWidth: function () {
      document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");
    },
    fnGetPages: function () {
      this.pagesData = []
      this.pages = document.getElementsByTagName('page')
      for (let i = 0; i < this.pages.length; i++) {
        this.pagesData.push(JSON.parse(this.pages[i].dataset.source))
      }
    },
    fnGetFirstScroll: function () {
      return this.fnScroll(this.forcepage ? this.fnGetPageIndexBySlug(this.forcepage) : 0)
    },
    fnGetPageIndexBySlug: function (slug) {
      return _.findIndex(this.pagesData, el => el.slug === slug)
    },
    fnScroll: function (page) {
      // Check inMove on wheel scroll only to bypass huge scroll
      this.inMove = true;
      this.activePage = page
      // Moving the page
      setTimeout(() => {
        this.pages[this.activePage].scrollIntoView({ behavior: 'smooth', inline: 'start' })
      }, this.inMoveDelay);
      // Update page URL
      this.fnUpdateURL(this.currentPageData.link)
      // Wait 1s for new scroll event
      setTimeout(() => {
        this.fnCloseAside()
        this.inMove = false
      }, this.inMoveStay);
      this.gaSend()
    },
    fnUpdateURL: function (url) {
      window.history.pushState({}, '', this.baseroute.concat('/', url));
    },
    fnNextPage: function () {
      if (this.hasNextPage) {
        this.activePage++;
        this.fnScroll(this.activePage);
      }
    },
    fnPreviousPage: function () {
      if (this.hasPreviousPage) {
        this.activePage--;
        this.fnScroll(this.activePage);
      }
    },
    fnToggleContent: function (ref) {
      this.toggleContent = !this.toggleContent
      if (this.toggleContent) {
        this.toggleContentData = document.querySelector('[content="' + ref + '"]')
      }
    },
    // User interactions
    fnHandleKeyDown: function (e) {
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowRight':
          this.fnNextPage()
          e.preventDefault()
          break;
        case 'ArrowUp':
        case 'ArrowLeft':
          this.fnPreviousPage()
          e.preventDefault()
          break;
      }
      return false;
    },
    fnHandleScrollEvent: function (e) {
      e.preventDefault()
      if (!this.inMove) {
        if (e.deltaY > this.tolerance) {
          this.fnNextPage()
        } else if (e.deltaY < 0 && Math.abs(e.deltaY) > this.tolerance) {
          this.fnPreviousPage()
        } else if (e.deltaX > this.tolerance) {
          this.fnNextPage()
        } else if (e.deltaX < 0 && Math.abs(e.deltaX) > this.tolerance) {
          this.fnPreviousPage()
        }
      }
      return false
    },
    handleTouchStart: function (e) {
      // Hack for blocking scroll if content is opened
      if (!this.preScreen) {
        this.touchStartX = e.touches[0].clientX;
        this.touchStartY = e.touches[0].clientY;
        window.addEventListener('touchend', (touchEvent) => this.handleTouchMove(touchEvent), { passive: false, once: true });
      }
    },
    handleTouchMove: function (e) {
      if (!this.inMove) {
        const currentX = e.changedTouches[0].clientX
        const currentY = e.changedTouches[0].clientY
        if (Math.abs(this.touchStartX - currentX) >= this.touchTolerance) {
          if (this.touchStartX - currentX > 0) {
            this.fnNextCard()
          } else {
            this.fnPreviousCard()
          }
          return true;
        }
        if (Math.abs(this.touchStartY - currentY) >= this.touchTolerance) {
          if (this.touchStartY - currentY > 0) {
            this.fnNextPage()
          } else {
            this.fnPreviousPage()
          }
          return true;
        }
      } return false;
    },
    setListeners: function () {
      window.addEventListener('resize', this.onResize);
      window.addEventListener('wheel', this.fnHandleScrollEvent, { passive: false });
      window.addEventListener('keydown', this.fnHandleKeyDown);
    },
    unsetListeners: function () {
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('wheel', this.fnHandleScrollEvent, { passive: false });
      window.removeEventListener('keydown', this.fnHandleKeyDown);
    },
    gaSend: function () {
      gtag('event', 'bit_page_view', {
        name: this.currentPageData.name,
        type: this.currentPageData.category ? this.currentPageData.category.name : '',
        id: this.currentPageData.slug
      })
    }
  }
}
</script>

<style>
.fromleft-enter,
.fromleft-leave-to {
  transform: translateX(-200%);
}

.fromleft-leave-active,
.fromleft-enter-active {
  transition: .5s;
}

.fromleft-enter-active {
  transition-delay: .75s;
}

.fromright-enter,
.fromright-leave-to {
  transform: translateX(300%);
}

.fromright-leave-active,
.fromright-enter-active {
  transition: .5s;
}

.fromright-enter-active {
  transition-delay: .45s;
}

.fromtop-enter,
.fromtop-leave-to {
  transform: translateY(-200%);
}

.fromtop-leave-active,
.fromtop-enter-active {
  transition: .5s;
}

.fromtop-enter-active {
  transition-delay: .85s;
}

.frombottom-enter,
.frombottom-leave-to {
  transform: translateY(200%);
}

.frombottom-leave-active,
.frombottom-enter-active {
  transition: .5s;
}

.frombottom-enter-active {
  transition-delay: .5s;
}

.aside-enter,
.aside-leave-to {
  transform: translateX(-100%);
}

.aside-leave-active,
.aside-enter-active {
  transition: .35s;
}

.fadeHeight {
  transition: all .35s ease-in-out;
}
</style>